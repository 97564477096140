.table {
  border: none;
  .table-card {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;

    .table-card_name {
      vertical-align: middle;
      min-width: 320px;
      padding: 1rem 0;
      display: flex;
      align-items: center;

      @media only screen and (min-width: 1000px) {
        min-width: 450px;
      }

      @media only screen and (min-width: 1200px) {
        min-width: 80%;
        padding: 0;
      }

      p {
        margin: 0;
      }
    }

    .table-action {
      @media only screen and (min-width: 1200px) {
        min-width: 20%;
      }

      button {
        width: 100%;
      }
    }
  }

  .table__name {
    width: 100%;
    @media only screen and (min-width: 1000px) {
      width: 240px;
    }
  }
  .svg-icon {
    border-radius: 6px;
    overflow: hidden;
    height: inherit;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.preview-card {
  .symbol.symbol-60 .symbol-label {
    width: 120px;
    height: 120px;
  }
  table {
    padding: 1rem;
    display: block;

    tr {
      width: 100%;
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0.5rem 1rem;

      td {
        &:first-child {
          width: 160px;
        }
      }
    }
  }
}
