.form-login,
.form-forgot_password {
  input {
    font-size: 1.25rem !important;
  }
}

.input-relative {
  position: relative;
}

.metro {
  width: 100% !important;
  height: 100% !important;
}

.btn__show {
  position: absolute;
  right: 5%;
  bottom: 0;
  top: 0;
}
