.review_application-body {
  background: white;

  &.is-loading {
    height: 100vh;
  }

  .form-group {
    label {
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  input[type='checkbox'] {
    float: right;
    margin-left: 1%;
    width: 20px;
    height: 20px;
  }

  b {
    font-size: 1.25rem;
    margin: 1rem auto;
    display: block;
    width: 100%;
  }

  h2 {
    margin-top: 3%;
    font-size: 1.175rem;
  }

  table {
    width: 100%;
    &.self-medical,
    &.surgery-medical,
    &.family-medical {
      padding-right: 1rem;

      b {
        font-size: 1.25rem;
      }

      label {
        font-size: 1rem;
      }
    }

    td {
      font-size: 1.075rem;
      padding: 0.5rem 0;
    }
  }
}

.resume-card {
  .resume_header {
    p {
      display: flex;
      font-size: 1rem !important;
      align-items: center;
      margin: 0 !important;
      justify-content: flex-start;
    }

    b {
      margin: 0;
      padding: 0 1rem;
    }
  }

  hr {
    margin: 1rem auto !important;
  }

  b {
    font-size: 1.175rem;
    margin-bottom: 0;
  }

  table {
    td {
      &:first-child {
        font-size: 1rem;
        width: 200px;
      }
    }
  }

  input[type='checkbox'] {
    height: 1rem;
    width: 1rem;
  }

  label {
    margin: 0 auto !important;
  }
}

.review-container-fluid {
  background: white;
  .form {
    background-color: #f2f2f2;
    padding: 2rem 1.25rem;
    p {
      font-size: 1.2rem;
    }
    label {
      font-size: 1rem;
      margin-top: 1rem;
    }

    textarea {
      height: 60px;
    }

    .sub-header {
      font-size: 0.95rem;
    }

    .appearance,
    .characteristics {
      .row:first-child {
        label {
          margin-top: 0;
        }
      }
      label {
        margin-top: 2rem;
      }
    }

    h4 {
      margin: 2rem auto 1.25rem auto;
      font-size: 1.175rem;
      font-weight: bold;
    }
    hr {
      margin: 2rem auto;
    }

    .form-check {
      padding: 0;
      input {
        float: right;
        width: 20px;
        height: 20px;
        position: relative;
        margin-left: 0;
        margin-right: 2%;
      }

      label {
        display: block;
      }
    }
  }
  .preview {
    .form {
      background: white;
      padding: 1rem 2rem;
    }
  }
}
