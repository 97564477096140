.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
  transform: translateY(50%);
  .preloader-container {
    text-align: center;
    svg {
      width: 50% !important;
      height: 50% !important;
      margin: 0 auto;
    }
  }
}
